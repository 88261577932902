import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { diagramLinkSelector, perspectiveSelector, schemaSelector } from '../../../redux/selectors';
import { DiagramContext } from '../context/DiagramContext';
import { ORGANIZATION_PERSPECTIVE, ORGANIZATION_TYPE, ORGANIZATION_VIEW } from '../../../constants';
import {
  changePerspective,
  changeView,
  goToNode,
  hasNodeLink,
  setHighlightedTgi,
  updateSchema,
  setExpandPath,
  searchGuest,
  searchExcom,
  searchPowerGuest,
} from '../../../redux/actions';
import { removeCountryCode } from '../../../../../utils/countryUtils';
import initialstate from '../../../redux/initialstate';
import * as actions from '../../../redux/actions';

export default function useLink() {
  const dispatch = useDispatch();
  const schema = useSelector(schemaSelector);
  const context = useContext(DiagramContext);
  const perspective = useSelector(perspectiveSelector);
  const nodeLink = useSelector(diagramLinkSelector);
  const [reqCount, setRequestCount] = useState(0);
  const [item, setItem] = useState(null);
  const [done, setDone] = useState(false);
  const [payloads, setPayloads] = useState(null);
  const [maxRequests, setMaxRequests] = useState(1);

  useEffect(() => {
    if (!nodeLink) return;
    setRequestCount(0);
    setDone(false);

    dispatch(setHighlightedTgi(''));
    dispatch(setHighlightedTgi(nodeLink.unId));
    if (isCer()) {
      setItem(nodeLink);
    }
    if (isCountryRequest()) {
      dispatch(changePerspective(ORGANIZATION_PERSPECTIVE.BY_COUNTRY));
      dispatch(updateSchema(initialstate[actions.DIAGRAM_SCHEMA]));
    }
    if (perspective === ORGANIZATION_PERSPECTIVE.BY_COUNTRY) {
      if (!isCer() && !isCountryRequest() && !isThalesGroupRequest()) {
        dispatch(changePerspective(ORGANIZATION_PERSPECTIVE.BY_GBU));
        dispatch(updateSchema(initialstate[actions.DIAGRAM_SCHEMA]));
      } else {
        setItem(nodeLink);
      }
    } else {
      setItem(nodeLink);
    }
  }, [nodeLink]);

  useEffect(() => {
    if ((nodeLink && ['EXCOM', 'CER'].includes(nodeLink.code)) || isExcomLink()) {
      setTimeout(() => {
        setItem(nodeLink);
      }, 500);
    }
  }, [perspective]);

  useEffect(() => {
    if (item) {
      link();
    }
  }, [item]);

  useEffect(() => {
    if (!item) {
      return;
    }
    if (done) {
      setItem(null);
      setPayloads([]);
      dispatch(changeView(ORGANIZATION_VIEW.DETAILS));
      dispatch(hasNodeLink(false));
      dispatch(goToNode(null));
    } else {
      dispatch(changeView(ORGANIZATION_VIEW.EMPTY));
    }
  }, [done]);

  useEffect(() => {
    if (!item) {
      return;
    }

    if (reqCount === maxRequests) {
      return setDone(true);
    }

    if (perspective === ORGANIZATION_PERSPECTIVE.BY_COUNTRY) {
      (async () => {
        try {
          const payload = payloads[reqCount];
          await reqOnCountry(payload);
        } catch (e) {
          console.warn(e);
        }
      })();
    }

    if (perspective === ORGANIZATION_PERSPECTIVE.BY_GBU) {
      (async () => {
        try {
          const payload = payloads[reqCount];
          await reqOnGbu(payload);
        } catch (e) {
          console.warn(e);
        }
      })();
    }
  }, [payloads, schema, reqCount]);

  /**
   * Determine the nodeLink type and trigger the organization requests batch
   *
   * @returns {Promise<*>}
   */
  const link = async () => {
    const payloads = getPayloads();
    setMaxRequests(payloads.length);
    setPayloads(() => {
      return payloads.map((payload, index) => {
        payload.onlyChild = index !== payloads.length - 1;

        return payload;
      });
    });
  };

  const isCountryRequest = () => {
    if (perspective !== ORGANIZATION_PERSPECTIVE.BY_GBU) {
      return true;
    }
    return (
      nodeLink && nodeLink.hasOwnProperty('type') && ['country', 'region'].includes(nodeLink.type)
    );
  };
  const isThalesGroupRequest = () => {
    return (
      nodeLink &&
      nodeLink.hasOwnProperty('detail') &&
      nodeLink.detail.toLowerCase().includes('thales group')
    );
  };

  const reqOnCountry = async payload => {
    if (payload.type === ORGANIZATION_TYPE.GROUP_COUNTRY) {
      await context.fetchAndSelectNode2(payload);
    }

    if (payload.hasOwnProperty('blId') && payload.type === ORGANIZATION_TYPE.BL) {
      const region = schema.columns[1].nodes.find(node => {
        return node.name === payload.regionName;
      });

      payload.id = region.id;
      payload.region = region.name;
      payload.parentCountryId = null;

      await context.fetchAndSelectNode2(payload);
    }

    if (payload.type === ORGANIZATION_TYPE.REGION) {
      const region = schema.columns[1].nodes.find(node => {
        return node.name === payload.regionName;
      });

      payload.id = region.id;
      payload.region = region.name;
      payload.parentCountryId = null;

      await context.fetchAndSelectNode2(payload);
    }

    if (payload.type === ORGANIZATION_TYPE.COUNTRY) {
      await context.fetchAndSelectNode2(payload);
    }

    if (payload.type === ORGANIZATION_TYPE.DOMAIN) {
      await context.fetchAndSelectNode2(payload);
    }

    if (payload.type === ORGANIZATION_TYPE.CBU) {
      await context.fetchAndSelectNode2(payload);
    }

    if (payload.hasOwnProperty('blId') && payload.type === ORGANIZATION_TYPE.CC) {
      const blNodes = schema.columns.find(column => column.type === ORGANIZATION_TYPE.DOMAIN).nodes;
      const blNode = blNodes.find(node => node.id === payload.blId);
      payload.blCode = removeCountryCode(blNode.code);

      await context.fetchAndSelectNode2(payload);
    }

    setRequestCount(reqCount + 1);
  };

  const reqOnGbu = async payload => {
    if (payload.hasOwnProperty('blId') && payload.type === ORGANIZATION_TYPE.CC) {
      const blNodes = schema.columns.find(column => column.type === ORGANIZATION_TYPE.BL).nodes;
      const blNode = blNodes.find(node => node.id === payload.blId);
      payload.blCode = blNode.code;
      payload.region = null;
      await context.fetchAndSelectNode2(payload);
    } else {
      await context.fetchAndSelectNode2(payload);
    }

    setRequestCount(currentCount => currentCount + 1);
  };

  const getPayloads = () => {
    const payloads = [];
    let count = 1;
    const selectedPaths = getSelectedPaths();
    dispatch(searchGuest(false));
    dispatch(searchPowerGuest(false));
    dispatch(searchExcom(false));

    if (item.hasOwnProperty('detail')) {
      const details = item.detail;
      if (details.toLowerCase().includes('power')) {
        dispatch(searchPowerGuest(true));
      }

      if (details.toLowerCase().includes('guest') && !details.toLowerCase().includes('power')) {
        dispatch(searchGuest(true));
      }

      if (details.toLowerCase().includes('excom')) {
        dispatch(searchExcom(true));
      }
    }

    if (item.hasOwnProperty('type') && item.type === null) {
      count = 1;
    }

    if (item.hasOwnProperty('level') && item.level !== null) {
      count = item.level === 0 ? 1 : item.level;
    }

    if (perspective === ORGANIZATION_PERSPECTIVE.BY_COUNTRY) {
      if (item.level === 0 && !isThalesGroupRequest()) {
        count = 2;
      }
      if (item.level > 0) {
        count = item.level + 2;
      }

      if (isCer()) {
        count = 3;
      }

      // to open CERs on By Country perspective, we need to force the payload count to country level
      if (['bu', 'bl'].includes(item.type) && isCer()) {
        count = 3;
      }

      for (let i = 1; i <= count; i++) {
        const payload = { id: '', type: '' };

        if (i === 1) {
          payload.id = '00053af6-7fba-4db8-8572-2d7e23332b6e'; // Generic id for GROUP node
          payload.type = ORGANIZATION_TYPE.GROUP_COUNTRY;
        }

        if (i === 2) {
          payload.regionName = item.regionName || item.label;
          payload.parentCountryId = null;
          payload.type = ORGANIZATION_TYPE.REGION;
        }

        if (i === 3) {
          payload.id = item.parentCountryId || item.id;
          payload.parentCountryId = null;
          payload.type = ORGANIZATION_TYPE.COUNTRY;
        }

        if (i === 4) {
          payload.id = item.parentBuId || item.id;
          payload.parentCountryId = item.parentCountryId;
          payload.type = ORGANIZATION_TYPE.CBU;
        }

        if (i === 5) {
          payload.id = item.parentBlId || item.id;
          payload.parentCountryId = item.parentCountryId;
          payload.type = ORGANIZATION_TYPE.DOMAIN;
        }

        if (i === 6) {
          payload.id = item.id;
          payload.parentCountryId = item.parentCountryId;
          payload.blId = item.parentBlId;
          payload.type = ORGANIZATION_TYPE.CC;
        }

        if (item.hasOwnProperty('expandPath') && item.expandPath.length > 0) {
          dispatch(setExpandPath(item.expandPath));
        }

        payloads.push(payload);
      }

      return payloads;
    }

    if (perspective === ORGANIZATION_PERSPECTIVE.BY_GBU) {
      if (isCer()) {
        count = 2;
      }
      for (let i = 1; i <= count; i++) {
        const payload = { id: '', type: '' };

        if (i === 1) {
          payload.id = '00053af6-7fba-4db8-8572-2d7e23332b6e'; // Generic id for GROUP node
          payload.type = ORGANIZATION_TYPE.GROUP;
        }
        if (i === 2) {
          payload.id = item.parentBuId || item.id;
          payload.type = ORGANIZATION_TYPE.GBU;
        }

        if (i === 3) {
          payload.id = item.parentBlId || item.id;
          payload.type = ORGANIZATION_TYPE.BL;
        }

        if (i === 4) {
          payload.id = item.id;
          payload.type = ORGANIZATION_TYPE.CC;
          payload.blId = item.parentBlId;
        }

        if (item.hasOwnProperty('expandPath') && item.expandPath.length > 0) {
          dispatch(setExpandPath(item.expandPath));
        }

        payloads.push(payload);
      }

      if (item.level === 0 && !isCer()) {
        return payloads;
      }

      return payloads.filter(payload => {
        if (payload.id === item.id) {
          return true;
        }

        if (payload.type === 'GROUP' && schema.columns[0].nodes[1].selected) {
          return false;
        }

        return !selectedPaths.includes(payload.id);
      });
    }

    return payloads;
  };

  const getSelectedPaths = () => {
    return Object.keys(schema.path)
      .map(key => schema.path[key].id)
      .filter(id => id !== null);
  };

  /**
   * Check if the nodeLink is of type excom
   *
   * @returns {boolean}
   */
  const isExcomLink = () => {
    const isExcomNode = () => {
      if (nodeLink && nodeLink.hasOwnProperty('details')) {
        return nodeLink.detail.toLowerCase().includes('excom');
      } else if (nodeLink && nodeLink.hasOwnProperty('type')) {
        return nodeLink.type === 'EXCOM';
      }
    };
    return nodeLink && ['region', 'country', 'bu'].includes(nodeLink.type) && isExcomNode;
  };

  const isCer = () => {
    if (nodeLink && nodeLink.hasOwnProperty('code')) {
      return nodeLink.code === 'CER';
    }

    if (nodeLink && nodeLink.hasOwnProperty('detail')) {
      return nodeLink.detail.toLowerCase().includes('country engineering representative');
    }

    return false;
  };

  return {};
}
